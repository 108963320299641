<template>
  <div :class="getMarBox(pageType)">
    <Header></Header>
    <div class="linkBox">
      <div @click="backHome()" class="linkBoxItem">首页</div>
      <div class="linkBoxItem" v-if="pageType!=='catePage'" style="color: #606266;">{{ getTopName(pageType) }}</div>
      <div v-for="(item,index) in breads" :key="index" class="linkBoxItem" @click="navChange(item,index)"
           v-if="pageType==='catePage'" :style="item.name===nowName?'color:#606266;font-weight:400;':''">{{ item.name }}
      </div>
    </div>
    <div :class="getMainBox(pageType)">
      <div class="leftBox" v-if="pageType==='catePage'">
        <div class="leftBox_line1">{{ nowName || '作业标准' }}</div>
        <div class="leftBox_cateBox">
          <div class="leftBox_cates" v-for="(cas,index) in cateList" :key="index" @click="cateChange(cas)">
            <!--            <p :class="cas.act?'actives':''">{{ cas.name }}</p>-->
            {{ cas.name }}
          </div>
        </div>
      </div>
      <div :class="getRightBox(pageType)">
        <div class="rightBox_box" v-if="pageType!=='shares'">
          <div :class="getRightItemBox(pageType)" v-for="r in dataList" @click="toArticle(r)">
            <div class="rightBox_item_info_title">{{ r.title }}</div>
            <div class="rightBox_item_img" v-if="pageType==='catePage'">
              <el-image :src="r.coverImg" style="width: 100%;" lazy></el-image>
            </div>
            <div class="rightBox_item_img a"
                 v-if="pageType==='classRoom'||pageType === 'C'||pageType === 'G'|| pageType === 'F'|| pageType === 'I'">
              <el-image v-for="(imgs,indexs) in r.coverImgs" :key="indexs+'a'" :src="imgs"
                        style="width: 36%;margin:1px;"
                        lazy></el-image>
            </div>
            <div class="rightBox_item_info">
              <div class="rightBox_item_info_texts" v-if="r.description">{{ r.description }}</div>
              <div class="rightBox_item_info_views">
                <div>{{ r.createTime }}</div>
                <div><i class="el-icon-view"></i><span class="views_spans">{{ r.views }}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightBox_box" v-if="pageType==='shares'">
          <div class="rightBox_item" v-for="r in dataList">
            <div class="shareAvBox">
              <img :src="shareAvatar" class="shareAvBox_av" alt=""/>
              <div style="margin-left: 10px;">
                <div style="font-size: 15px;font-weight: bold;">百乐</div>
                <div style="color: #989898;font-size: 15px;">{{ r.createTime }}</div>
              </div>
            </div>
            <div class="rightBox_item_info">
              <div v-html="r.content" class="rightBox_item_info_title share"></div>
            </div>
            <div class="share_link" v-if="r.shareUrl">
              <a :href="checkUrl(r.shareUrl)" target="_blank">
                <span v-if="!r.shareUrlImg">网页链接：</span>
                <div style="display: flex;align-items: center;">
                  <img :src="r.shareUrlImg" alt="" style="width: 60px;height: 60px;" v-if="r.shareUrlImg">
                  <div class="share_link_titles">{{ r.shareUrlTitle }}</div>
                </div>
              </a>
            </div>
            <div class="shareImglist" v-if="r.photoList">
              <el-image class="shareImgs" v-for="(imgs,indexs) in r.photoList" :key="indexs+'a'" :src="imgs"
                        lazy :preview-src-list="r.photoList"></el-image>
            </div>
            <video class="shareVideo" preload="auto" align="center" controls="true" :poster="r.videoCoverUrl"
                   v-if="r.videoUrl">
              <source :src="r.videoUrl" type="video/mp4"/>
            </video>
          </div>
        </div>
        <div>
          <div v-if="dataList.length>0">
            <el-pagination
              class="pageBox"
              small
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="paging.pageSize"
              :current-page="paging.pageNum"
              :total="total">
            </el-pagination>
          </div>
          <el-empty description="暂无内容" v-else></el-empty>
        </div>
      </div>
    </div>
    <div v-if="showBtn" class="backTopBox" @click="backTop">
      <i class="el-icon-upload2"></i>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {get, postJson} from "@/utils/request";
import deImg from "@/assets/index/pc-b.jpg";

let defalutImg = require("@/assets/shareAva.png");

export default {
  data() {
    return {
      t: '',
      cateList: [],
      nowCateId: 0,
      nowName: '',
      breads: [{id: '0', name: '作业标准'}],
      dataList: [],
      total: 0,
      paging: {
        pageNum: 1,
        pageSize: 10,
      },
      showBtn: false,
      pageType: 'catePage', //当前页面类型(分类、课堂)
      shareAvatar: '', //分享头像
      navClick: false, //是否点击头部
    };
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
    if (this.pageType === 'catePage') {
      // 添加 popstate 事件监听
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', this.onPopState);
    }
  },
  created() {
    this.getWebInfo()
  },
  watch: {
    "$route.query": {
      handler() {
        let arr = this.$route.query;
        // console.log('watch-arr：', arr);
        if (arr.type === 'A') {
          if (arr.cid && arr.t) {
            this.nowCateId = arr.cid;
            this.nowName = arr.cname;
            this.pageType = 'catePage';
            this.t = arr.t;
            this.cateList = [];
            this.getCategories()
          } else {
            this.$router.replace('/errorPage')
          }
        } else if (arr.type === 'B') {
          this.pageType = 'classRoom'
          this.getdataList()
        } else if (arr.type === 'share') {
          this.pageType = 'shares'
          this.getdataList()
        } else {
          this.pageType = arr.type
          this.getdataList()
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    async getWebInfo() {
      if (this.pageType === 'catePage') {
        this.$nextTick(() => {
          const bodyStyle = document.body.style;
          bodyStyle.background = "#ffffff";
        });
      } else {
        await get("/api/webPage/getWebPageInfo").then(res => {
          if (res && res.data) {
            let backImg = res.data.backgroundImageUrl || deImg;
            this.setBgImg(backImg)
          } else {
            this.setBgImg(deImg)
          }
        }).catch(() => {
          this.setBgImg(deImg)
        })
      }
    },
    setBgImg(img) {
      this.$nextTick(() => {
        const bodyStyle = document.body.style, // 获取body节点样式
          docEl = document.documentElement,
          docBody = document.body,
          winWidth = docEl.clientWidth || docBody.clientWidth, // 获取浏览器的宽度
          WinHeight = docEl.clientHeight || docBody.clientHeight; // 获取浏览器的高度
        if (winWidth > 850) {
          let sty = "url(" + img + ") #b4daef no-repeat";
          bodyStyle.background = sty;
          bodyStyle.backgroundAttachment = "fixed";
        } else {
          bodyStyle.background = "#ffffff";
        }
      });
    },
    getMarBox(r) {
      if (r === 'catePage') {
        return ''
      } else {
        return 'MarBox'
      }
    },
    getRightItemBox(r) {
      if (r === 'catePage') {
        return 'rightBox_item n'
      } else {
        return 'rightBox_item'
      }
    },
    async getCategories() {
      await get("/api/wiki/getAllWikiCategory", {categoryId: this.nowCateId,}).then(res => {
        if (res.data && res.data.length > 0) {
          if (this.cateList.length > 0) {
            if (this.breads.length > 0) { //若已存在面包屑
              let findHave = this.breads.findIndex(i => i.id === this.nowCateId);
              if (findHave === -1) {
                this.breads = this.breads.concat({
                  id: this.nowCateId,
                  name: this.nowName
                })
              }
            } else {
              this.breads = this.breads.concat({
                id: this.nowCateId,
                name: this.nowName
              })
            }
          } else { //分类为空
            if (this.nowCateId !== '0') {
              let findHave = this.breads.findIndex(i => i.id === this.nowCateId);
              if (findHave === -1) {
                this.breads = this.breads.concat({
                  id: this.nowCateId,
                  name: this.nowName
                })
              }
            }
          }
          this.cateList = res.data;
        } else { //无分类
          if (this.nowCateId !== '0') {
            let findHave = this.breads.findIndex(i => i.id === this.nowCateId);
            if (findHave === -1) {
              this.breads = this.breads.concat({
                id: this.nowCateId,
                name: this.nowName
              })
            }
          }
        }
        if (this.nowCateId) {
          this.getdataList()
        }
      })
    },
    async getdataList() {
      let param = {
        pageSize: this.paging.pageSize,
        pageNum: this.paging.pageNum,
      };
      let apiUrl = '/api/wiki/apiGetWikiList';
      if (this.pageType === 'catePage') { //企业文化（百科）
        param.categoryId = this.nowCateId;
        param.type = 'A';
      } else if (this.pageType === 'classRoom') { //课堂
        param.type = 'B';
      } else if (this.pageType === 'shares') { //分享
        apiUrl = '/api/share/apiGetShareList'
      } else if (this.pageType === 'C') { //招聘
        param.type = 'C';
      } else if (this.pageType === 'G') { //文化中心
        param.type = 'G';
      } else if (this.pageType === 'F') { //关于百乐
        param.type = 'F';
      } else if (this.pageType === 'I') { //加盟中心
        param.type = 'I';
      } else if (this.pageType === 'J') { //企业文化
        param.type = 'J';
      }
      await get(apiUrl, param).then(res => {
        if (this.pageType === 'classRoom' || this.pageType === 'C' || this.pageType === 'G' || this.pageType === 'F' || this.pageType === 'I') {
          res.data.rows.forEach(i => {
            i.coverImgs = i.coverImgs.split(',')
          })
          // console.log(res.data.rows)
        } else if (this.pageType === 'shares') {
          res.data.rows.forEach(i => {
            i.photoList ? i.photoList = i.photoList.split(',') : '';
          })
          this.getAvatar()
        }
        this.dataList = res.data.rows;
        this.total = res.data.total;
      })
    },
    async getAvatar() {
      await get("/api/webPage/getShareAvatar").then(res => {
        if (res && res.data) {
          this.shareAvatar = res.data || defalutImg;
        } else {
          this.shareAvatar = defalutImg;
        }
      })
    },
    cateChange(record) {
      // console.log('左侧点击', record);
      this.navClick = true;
      this.dataList = []
      this.paging.pageNum = 1
      this.$router.push({
        path: "Home",
        query: {
          t: this.t,
          type: 'A',
          cid: record.id,
          cname: record.name
        }
      })
      // location.reload();
      // this.$router.go(0)
    },
    navChange(e, index) {
      // console.log('头部点击', JSON.stringify(e));
      this.navClick = true;
      if (e.id === '0') {
        this.breads = [{
          id: '0',
          name: '作业标准'
          // name: '企业文化'
        }]
        this.$router.push({
          path: "Home",
          query: {
            t: this.t,
            type: 'A',
            cid: e.id,
            cname: e.name
          }
        })
      } else {
        let arr = this.breads;
        let last = arr[arr.length - 1];
        if (last.id === e.id) { //分类自刷新（无跳转页面）
          this.dataList = []
          this.paging.pageNum = 1
          this.cateList = [];
          this.getCategories()
        } else {
          arr = arr.splice(index + 1, 1)
          this.$router.push({
            path: "Home",
            query: {
              t: this.t,
              type: 'A',
              cid: e.id,
              cname: e.name
            }
          })
        }
      }
    },
    onPopState(e) {
      if (this.pageType === 'catePage') {
        let arr = this.breads;
        arr.pop(-1);
        // window.history.pushState(null, null, window.location.href)
        if (this.breads.length > 0) {
          if (this.navClick) {
            let lastArr = this.breads[this.breads.length - 1];
            if (lastArr) {
              let par = {
                id: lastArr.id,
                name: lastArr.name,
                t: this.t,
                type: 'A'
              }
              this.cateChange(par)
            }
          } else {
            window.removeEventListener('popstate', this.onPopState)
            this.$router.replace({
              path: "/CateIndex",
              query: {cid: 0, type: 'A', t: this.t}
            })
          }
        } else {
          window.removeEventListener('popstate', this.onPopState)
          this.$router.replace({
            path: "/CateIndex",
            query: {cid: 0, type: 'A', t: this.t}
          })
        }
      } else {
        window.removeEventListener('popstate', this.onPopState)
        window.history.go(-1)
      }
    },
    checkUrl(url) {
      let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      if (!reg.test(url)) {
        return 'https://' + url;
      } else {
        return url;
      }
    },
    // 回到顶部
    showbtn() {
      let that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 900) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    toArticle(item) {
      // 编程式导航跳转
      this.$router.push({
        path: "article",
        query: {
          id: item.id
        }
      })
    },
    getTopName(r) {
      if (r === 'catePage') {
        return '企业文化'
      } else if (r === 'classRoom') {
        return '百乐课堂'
      } else if (r === 'shares') {
        return '百乐分享'
      } else if (r === 'C') {
        return '招聘中心'
      } else if (r === 'G') {
        return '文化中心'
      } else if (r === 'F') {
        return '关于百乐'
      } else if (r === 'J') {
        return '企业文化'
      }
    },
    getMainBox(r) {
      if (r === 'catePage') {
        return 'mainBox'
      } else {
        return 'mainBox t'
      }
    },
    getRightBox(r) {
      if (r === 'catePage') {
        return 'rightBox'
      } else {
        return 'rightBox a'
      }
    },
    backTop() {
      document.documentElement.scrollTop = 0;
    },
    handleSizeChange(pageSize) {
      this.paging.pageSize = pageSize;
      this.paging.pageNum = 1;
      this.getdataList();
    },
    handleCurrentChange(page) {
      this.paging.pageNum = page;
      this.getdataList();
    },
    backHome() {
      if (this.pageType === 'catePage') {
        this.$router.replace({
          path: "/CateIndex",
          query: {
            t: this.t,
            type: 'A',
            cid: 0
          }
        })
      } else {
        this.$router.replace({
          path: "/Index",
        })
      }
    }
  },
  beforeDestroy() {
    // 在组件销毁前，移除 popstate 事件监听
    this.navClick = false
    window.removeEventListener('popstate', this.onPopState)
  }
};
</script>

<style lang='scss'>
.linkBox {
  width: 88%;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px auto;
  background-color: #F5F7FA;
  text-indent: 20px;
  border-radius: 8px;

  .linkBoxItem {
    color: #303133;
    font-size: 16px;
    font-weight: 700;
    margin: 3px;
  }

  .linkBoxItem::after {
    content: "/";
    font-size: 15px;
    margin-left: 5px;
    color: #c0c4cc;
    cursor: unset;
  }

  .linkBoxItem:last-child:after {
    color: #F5F7FA;
  }
}

.mainBox {
  border: 1px solid #cccccc;
}

.mainBox.t {
  border: unset;
}

.leftBox_line1 {
  width: 100%;
  color: #F57655;
  font-weight: bold;
}

.leftBox_cates:hover {
  cursor: pointer;
}

.rightBox {
  width: 82%;
  margin: 5px auto;
  border-left: 1px solid #cccccc;
}

.rightBox.a {
  width: 100%;
  border: unset;
}

.rightBox_item {
  border: 1px solid #f1f1f1;
  margin: 7px;
}

.rightBox_item_img {
  width: 100%;
  height: 265px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url('../assets/errImg.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.rightBox_item_img.a {
  background-image: unset;
  width: 99%;
  height: auto;
  margin: 4px auto;
  flex-direction: row;
}

.rightBox_item_info {
  position: relative;
  width: 97%;
}

.rightBox_item_info_title {
  color: #303133;
  text-indent: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis; //文本超出省略
  display: -webkit-box;
  -webkit-line-clamp: 2; //第几行省略就设置第几行
  -webkit-box-orient: vertical;
  font-family: "Microsoft YaHei", 微软雅黑, serif;
  margin-bottom: 7px;
}

.rightBox_item_info_title.share {
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
  line-height: unset;
  width: 100%;
  padding: 10px;
  font-weight: normal;
  display: block;
}

.rightBox_item_info_texts {
  width: 97%;
  margin: 0 auto;
  max-height: 37px;
  line-height: 19px;
  font-size: 0.6rem;
  color: #777777;
  overflow: hidden;
  text-overflow: ellipsis; //文本超出省略
  display: -webkit-box;
  -webkit-line-clamp: 2; //第几行省略就设置第几行
  -webkit-box-orient: vertical;
}

.rightBox_item_info_texts.share {
  font-size: 15px;
  padding: 10px 0 10px 0;
  overflow: unset;
  color: #666666;
  display: block;
}

.rightBox_item_info_views {
  text-align: right;
  width: 97%;
  color: #9d9d9d;
  margin: 3px auto;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageBox {
  text-align: center;
  margin: 20px 0;
}

.el-pagination {
  white-space: unset;
}

.actives {
  color: #BE5B24;
}

.backTopBox {
  background-color: rgba(0, 0, 0, 0.3);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  right: 180px;
  bottom: 10vh;
  font-size: 2.2rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareAvBox {
  width: 95%;
  display: flex;
  align-items: center;
  margin: 10px 0 7px 0;
}

.shareAvBox_av {
  width: 50px;
  height: 50px;
  margin: 1px;
  border-radius: 50%;
}

.share_link {
  width: 92%;
  margin: 10px auto;
  background-color: #f7f7f7;
  border-radius: 2px;
  padding: 13px;
}

.share_link_titles {
  margin: 5px;
  color: #504e4e;
  font-size: 15px;
  line-height: 24px;
  display: block;
  padding: 5px;
}

.shareVideo {
  width: 100%;
  height: auto !important;
  max-height: 400px;
}

.shareimgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgb(228, 226, 226);
}

.shareimgs > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.imgs:after {
  content: '';
  display: block;
  width: 100px;
}

.shareImglist {
  font-size: 0;
  zoom: 1;
  margin-bottom: 5px;
}

.shareImglist:before {
  content: "";
  display: block
}

.shareImglist:after {
  content: "";
  display: table;
  clear: both
}

.shareImgs {
  padding-left: 1px;
  padding-bottom: 1px;
  width: 33.2%;
  height: 177px;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

/*屏幕宽度小于*/
@media only screen and (max-width: 1000px) {
  .mainBox {
    display: flex;
    flex-wrap: wrap;
    width: 96%;
    margin: 0 auto;
  }
  .linkBox {
    height: auto;
    padding: 10px;
  }
  .leftBox {
    width: 96%;
    margin: 10px auto;
  }
  .leftBox_line1 {
    text-align: center;
    font-size: 19px;
    line-height: 60px;
    border-bottom: 1px solid #cccccc;
  }
  .leftBox_cateBox {
    display: flex;
    flex-wrap: wrap;
    width: 89%;
    margin: 30px auto 0;
  }
  .leftBox_cates {
    font-size: 18px;
    line-height: 40px;
    margin: 0 31px 0;
    min-width: 88px;
    text-align: left;
  }
  .rightBox {
    width: 96%;
    margin: 0 auto;
    border: unset;
  }
  .rightBox_box {
    display: flex;
    flex-wrap: wrap;
  }
  .rightBox_item {
    flex: 2 1 auto;
  }
  .rightBox_item_info {
    width: 98%;
  }
  .rightBox_item_info_title {
    font-size: 17px;
    margin: 5px 0 10px 0;
  }
  .rightBox_item_info_texts {
    font-size: 17px;
    line-height: 27px;
    max-height: 55px;
    width: 94%;
    margin: 0 auto;
  }
  .rightBox_item_info_views {
    width: 94%;
    font-size: 15px;
    position: relative;
    right: unset;
    bottom: unset;
    margin: 5px auto;

    .views_spans {
      margin-left: 4px;
    }
  }
  .backTopBox {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    right: 30px;
  }
  .shareAvBox {
    width: 92%;
    margin: 10px auto;
  }
  .shareImglist {
    min-height: 102px;
  }
  .shareImgs {
    height: 120px;
  }
}

/*屏幕宽度大于1000*/
@media only screen and (min-width: 1000px) {
  .MarBox {
    max-width: 580px;
    min-width: 360px;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    position: relative;
  }
  .mainBox {
    width: 88%;
    min-height: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .linkBoxItem:hover {
    cursor: pointer;
    color: #0189ec;
  }
  .leftBox {
    width: 18%;
  }
  .leftBox_line1 {
    text-align: unset;
    line-height: 60px;
    font-size: 18px;
    text-indent: 20px;
    border-bottom: 1px solid #cccccc;
  }
  .leftBox_cateBox {
    width: 100%;
  }
  .leftBox_cates {
    line-height: 60px;
    text-indent: 20px;
  }
  .rightBox_box {
    display: flex;
    flex-wrap: wrap;
  }
  .rightBox_item {
    width: 98%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .rightBox_item.n {
    width: 32%;
  }
  .rightBox_item_info_title {
    width: 95%;
    font-size: 1.2rem;
    margin: 6px auto;
  }
  .rightBox_item_info_views {
    font-size: 1rem;

    .views_spans {
      margin-left: 6px;
    }
  }
}
</style>
